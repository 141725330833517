import React from 'react';
import Img from 'gatsby-image';
import './Testimonials.scss';

const TestimonialItem = props => {
  const { avatar, testimonial, name, company } = props;
  return (
    <div className="testimonial-item">
      <div className="testimonial-avatar">
        <Img fluid={avatar.localFile.childImageSharp.fluid} />
      </div>
      <div className="testimonial-content">
        <blockquote>{testimonial.text}</blockquote>
        <h5>{name.text}</h5>
        <p>{company.text}</p>
      </div>
    </div>
  );
};

const Testimonials = ({ input }) => (
  <section className="testimonials-container">
    <div className="wrapper">
      <div className="testimonials-header">
        <h4>{input.primary.title.text}</h4>
        <p>{input.primary.content.text}</p>
      </div>
      <div className="testimonials">
        {input.items.map(testimonial => (
          <TestimonialItem key={testimonial.name.text} {...testimonial} />
        ))}
      </div>
    </div>
  </section>
);

export default Testimonials;

import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Slope } from 'components';
import './Hero.scss';

const isOdd = number => number % 2;

const HeroButtons = ({ buttons }) => {
  if (!buttons) return null;
  return (
    <div className="hero-buttons">
      {buttons.map((button, index) => {
        const isInternalLink = button.buttonLink.url.indexOf('#') !== -1;
        const LinkComponent = isInternalLink ? AnchorLink : Link;
        return (
          <LinkComponent
            key={button.buttonText.text}
            className={isOdd(index) ? 'button white' : 'button outline-white'}
            href={isInternalLink ? `#${button.buttonLink.url.split('#')[1]}` : null}
            to={isInternalLink ? null : button.buttonLink.url}
          >
            {button.buttonText.text}
          </LinkComponent>
        );
      })}
    </div>
  );
};

const Hero = ({ input }) => (
  <section className="hero-section">
    <div className="wrapper">
      <div className="hero-header">
        <h1>{input.primary.title.text}</h1>
        <p>{input.primary.content.text}</p>
      </div>
      <div className="hero-additional">
        <p>{input.primary.additional.text}</p>
        <HeroButtons buttons={input.items} />
      </div>
      <div className="hero-image">
        <Img fluid={input.primary.image.localFile.childImageSharp.fluid} />
      </div>
    </div>
    <Slope />
  </section>
);

export default Hero;

import SEO from './SEO';
import Footer from './Footer';
import Header from './Header';
import { Logo } from './Logo';
import { Navigation } from './Navigation';
import { Slope } from './Slope';
import SliceZone from './SliceZone';
import Layout from './Layout';

export { Footer, Layout, Logo, Navigation, Slope, SEO, SliceZone, Header };

import React from 'react';
import './IconGrid.scss';

const Icons = ({ icons }) => {
  if (!icons) return null;
  return (
    <div className="icon-grid">
      {icons.map(icon => (
        <div key={icon.title.text} className="icon-grid-item">
          <img className="icon-image" src={icon.icon.url} alt={icon.title.text} />
          <h3 className="icon-title">{icon.title.text}</h3>
          <p className="icon-content">{icon.content.text}</p>
        </div>
      ))}
    </div>
  );
};

const IconGrid = ({ input }) => (
  <section className="icon-grid-container">
    <div className="wrapper">
      <div className="icon-grid-header">
        <h2>{input.primary.title.text}</h2>
      </div>
      <Icons icons={input.items} />
    </div>
  </section>
);

export default IconGrid;

import React, { Component } from 'react';
import { Logo, Navigation } from 'components';
import './Header.scss';

class Header extends Component {
  render() {
    const { isHome } = this.props;
    return (
      <header className={`header ${isHome ? 'home-header' : ''}`}>
        <div className="wrapper">
          <Logo />
          <Navigation />
        </div>
      </header>
    );
  }
}

export default Header;

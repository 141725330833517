import React, { Component } from 'react';
import { Logo, Slope, Navigation } from 'components';
import './Footer.scss';

class Footer extends Component {
  render() {
    const { data } = this.props;
    return (
      <footer className="footer">
        <div className="wrapper">
          <div className="footer-left">
            <Logo />
            <span className="copyright">Copyright Hudle Pty Ltd - 2018</span>
          </div>
          <Navigation isFooter />
        </div>
      </footer>
    );
  }
}

export default Footer;

import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Slope } from 'components';
import './Accordion.scss';

const AccordionItem = props => {
  const { title, content, itemIndex, toggleAccordion, active } = props;
  return (
    <div className={`accordion-item ${active ? 'active' : ''}`}>
      <Link to="#" onClick={event => toggleAccordion(event, itemIndex + 1)}>
        {title.text}
      </Link>
      <p>{content.text}</p>
    </div>
  );
};

export default class Accordion extends Component {
  state = { active: 0 };

  toggleAccordion = (event, active) => {
    event.preventDefault();
    const { active: stateActive } = this.state;
    console.log('active', active);
    console.log('stateActive', stateActive);
    if (active === stateActive) return this.setState({ active: 0 });
    return this.setState({ active });
  };

  render() {
    const { active } = this.state;
    const { input } = this.props;
    const { primary, items } = input;
    const { belowPricingTable } = primary;
    // console.log('active', active);
    return (
      <section className={`accordion ${belowPricingTable ? 'below-pricing-table' : ''}`}>
        <div className="wrapper">
          {items.map((item, index) => (
            <AccordionItem
              {...item}
              key={item.title.text}
              itemIndex={index}
              toggleAccordion={this.toggleAccordion}
              active={index + 1 === active}
            />
          ))}
        </div>
        <Slope />
      </section>
    );
  }
}

import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './Navigation.scss';

export const Navigation = ({ isFooter }) => (
  <nav className={isFooter ? 'nav footer-nav' : 'nav header-nav'}>
    {!isFooter && (
      <>
        <AnchorLink className="nav-item" href="#top">
          Home
        </AnchorLink>
        <AnchorLink className="nav-item" href="#features">
          Features
        </AnchorLink>
        <AnchorLink className="nav-item" href="#pricing">
          Pricing
        </AnchorLink>
      </>
    )}
    <a className="nav-item" href="mailto:hello@hudle.app">
      Contact
    </a>
    <AnchorLink className="nav-item button outline-white" href="#signup">
      Free Trial
    </AnchorLink>
  </nav>
);

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Facebook } from 'components/SEO';

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

export default class SEO extends Component {
  state = { hasScrolled: false };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { hasScrolled } = this.state;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollTop > 0 && !hasScrolled) {
      this.setState({ hasScrolled: true });
    }
    if (scrollTop === 0 && hasScrolled) {
      this.setState({ hasScrolled: false });
    }
  };

  render() {
    const { title, desc, banner, pathname, article } = this.props;
    const { hasScrolled } = this.state;
    const bodyAttributes = { class: hasScrolled ? 'scrolled' : null };
    return (
      <StaticQuery
        query={query}
        render={({
          site: {
            buildTime,
            siteMetadata: {
              defaultTitle,
              titleAlt,
              shortName,
              author,
              siteLanguage,
              logo,
              siteUrl,
              pathPrefix,
              defaultDescription,
              defaultBanner,
            },
          },
        }) => {
          const seo = {
            title: title || defaultTitle,
            description: defaultDescription || desc,
            image: `${siteUrl}${banner || defaultBanner}`,
            url: `${siteUrl}${pathname || '/'}`,
          };

          const realPrefix = pathPrefix === '/' ? '' : pathPrefix;

          let schemaOrgJSONLD = [
            {
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              '@id': siteUrl,
              url: siteUrl,
              name: defaultTitle,
              alternateName: titleAlt || '',
            },
          ];

          if (article) {
            schemaOrgJSONLD = [
              {
                '@context': 'http://schema.org',
                '@type': 'BlogPosting',
                '@id': seo.url,
                url: seo.url,
                name: title,
                alternateName: titleAlt || '',
                headline: title,
                image: {
                  '@type': 'ImageObject',
                  url: seo.image,
                },
                description: seo.description,
                datePublished: buildTime,
                dateModified: buildTime,
                author: {
                  '@type': 'Person',
                  name: author,
                },
                publisher: {
                  '@type': 'Organization',
                  name: author,
                  logo: {
                    '@type': 'ImageObject',
                    url: siteUrl + realPrefix + logo,
                  },
                },
                isPartOf: siteUrl,
                mainEntityOfPage: {
                  '@type': 'WebSite',
                  '@id': siteUrl,
                },
              },
            ];
          }

          return (
            <>
              <Helmet title={seo.title} bodyAttributes={bodyAttributes}>
                <html lang={siteLanguage} />
                <meta name="description" content={seo.description} />
                <meta name="image" content={seo.image} />
                <meta name="apple-mobile-web-app-title" content={shortName} />
                <meta name="application-name" content={shortName} />
                <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
              </Helmet>
              <Facebook
                desc={seo.description}
                image={seo.image}
                title={seo.title}
                type={article ? 'article' : null}
                url={seo.url}
              />
            </>
          );
        }}
      />
    );
  }
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        titleAlt
        shortName
        author
        siteLanguage
        logo
        siteUrl: url
        pathPrefix
        defaultDescription: description
        defaultBanner: banner
      }
    }
  }
`;

import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './Cta.scss';

const SignUpForm = () => (
  // eslint-disable-next-line
  <form className="form" name="join" method="POST" action="/thank-you" data-netlify="true" data-netlify-honeypot="honey">
    <div className="fields">
      <div className="field float honey">
        <label>Don’t fill this out if you're human:</label>
        <input name="honey" />
      </div>
      <input type="hidden" name="form-name" value="join" />
      <div className="field float">
        <label htmlFor="name">Your Name</label>
        <input id="name" name="name" type="text" placeholder="What's your name" required />
      </div>
      <div className="field float end-float">
        <label htmlFor="company">Company</label>
        <input id="company" name="company" type="text" placeholder="Name of company" />
      </div>
      <div className="field float">
        <label htmlFor="email">Email</label>
        <input id="email" name="email" type="email" placeholder="example@domain.com" required />
      </div>
      <div className="field float end-float">
        <label>Telephone</label>
        <input id="phone" name="phone" type="tel" placeholder="Eg. 0422 506 116" />
      </div>
      <div className="field float">
        <label>Select Plan</label>
        <select id="plan" name="plan">
          <option value="free">Free</option>
          <option value="medium">Hopper - starting from $29/month</option>
          <option value="enterprise">Royal - starting from $49/month</option>
        </select>
      </div>
      <div className="field float end-float">
        <label>Number of users</label>
        <select id="users" name="users">
          <option value="1">1 User</option>
          <option value="2-5">2-5 Users</option>
          <option value="6-10">6-10 Users</option>
          <option value="11-25">11-25 Users</option>
          <option value="26-50">26-50 Users</option>
          <option value="50-plus">50 plus</option>
        </select>
      </div>
      <button type="submit" className="button">
        Join
      </button>
    </div>
  </form>
);

const CtaButtons = ({ buttons }) => {
  if (!buttons) return null;
  const hasTwo = buttons.length === 2;
  return (
    <div className={`button-group ${hasTwo ? 'with-two' : ''}`}>
      {buttons.map((button, index) => {
        const isInternalLink = button.buttonLink.url.indexOf('#') !== -1;
        const LinkComponent = isInternalLink ? AnchorLink : Link;
        return (
          <LinkComponent
            key={button.buttonText.text}
            className={`button ${index === 0 && hasTwo ? 'outline' : ''}`}
            href={isInternalLink ? `#${button.buttonLink.url.split('#')[1]}` : null}
            to={isInternalLink ? null : button.buttonLink.url}
          >
            {button.buttonText.text}
          </LinkComponent>
        );
      })}
    </div>
  );
};

const Cta = ({ input }) => {
  const hasSignupForm = input.primary.hasSignUpForm === 'Yes';
  return (
    <section className="cta-section" id={hasSignupForm ? 'signup' : ''}>
      <div className="wrapper">
        <h3>{input.primary.title.text}</h3>
        <p>{input.primary.content.text}</p>
        <CtaButtons buttons={input.items} />
        {hasSignupForm && <SignUpForm />}
      </div>
    </section>
  );
};

export default Cta;

import React from 'react';
import './Slope.scss';

export const Slope = props => {
  const { position, background = '#FFFFFF' } = props;
  if (position === 'top') {
    return (
      <div className="slope top">
        <svg viewBox="0 0 1440 286" version="1.1">
          <g id="slope" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="slope-top" transform="translate(0.000000, -3171.000000)" fill={background}>
              <path
                d="M-4,3184.66637 C221.489583,3230.41402 465.55307,3230.41402 728.190459,3184.66637 C1018.06349,3149.80071 1258,3182.3777 1448,3282.39734 L1448,3284 L-4,3282.39734 L-4,3184.66637 Z"
                id="slope-shape"
                transform="translate(722.000000, 3227.574028) scale(1, -1) translate(-722.000000, -3227.574028)"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }

  return (
    <div className="slope">
      <svg viewBox="0 0 1440 113" version="1.1">
        <g id="slope" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="slope-bottom" transform="translate(0.000000, -3171.000000)" fill={background}>
            <path
              d="M-4,3184.66637 C221.489583,3230.41402 465.55307,3230.41402 728.190459,3184.66637 C1018.06349,3149.80071 1258,3182.3777 1448,3282.39734 L1448,3284 L-4,3282.39734 L-4,3184.66637 Z"
              id="slope-shape"
              transform="translate(722.000000, 3227.574028) scale(-1, 1) translate(-722.000000, -3227.574028)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

import React, { Component } from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './PricingTable.scss';

const PricingTablePlan = props => {
  const { className, name, price, description, features } = props;
  return (
    <div className={`pricing-table-plan ${className || ''}`}>
      <h4 className="pricing-table-plan-name">{name}</h4>
      <span className="pricing-table-plan-price">{price}</span>
      <p className="pricing-table-plan-description">{description}</p>
      <AnchorLink className="button" href="#signup">
        {price === 'FREE' ? 'Get Started' : 'Free Trial'}
      </AnchorLink>
      <div className="pricing-table-plan-features" dangerouslySetInnerHTML={{ __html: features.html }} />
    </div>
  );
};

export default class PricingTable extends Component {
  state = { activeTab: 5, mediumPlanPrice: null, enterPrisePlanPrice: null };

  componentWillMount() {
    const { input } = this.props;
    const activeTab = input.items[0].userCount;
    const mediumPlanPrice = input.items[0].mediumPlanPrice.text;
    const enterPrisePlanPrice = input.items[0].enterprisePlanPrice.text;
    this.setState({ activeTab, mediumPlanPrice, enterPrisePlanPrice });
  }

  setPlanPrice = (event, userCount) => {
    const { input } = this.props;
    event.preventDefault();
    const planPricing = input.items.find(plan => plan.userCount === userCount);
    const { mediumPlanPrice, enterprisePlanPrice } = planPricing;
    this.setState({
      activeTab: userCount,
      mediumPlanPrice: mediumPlanPrice.text,
      enterPrisePlanPrice: enterprisePlanPrice.text,
    });
  };

  render() {
    const { input } = this.props;
    const { activeTab, mediumPlanPrice, enterPrisePlanPrice } = this.state;
    return (
      <section className="pricing-table-container" id="pricing">
        <div className="pricing-table-header">
          <div className="wrapper">
            <h3>{input.primary.title.text}</h3>
            <p>{input.primary.content.text}</p>
          </div>
        </div>
        <div className="pricing-table">
          <div className="wrapper">
            <div className="pricing-table-col free-plan">
              <div className="pricing-table-tabs">
                <span className="pricing-table-tab active">Free - 1 User</span>
              </div>
              <PricingTablePlan
                price="FREE"
                className="free-plan"
                name={input.primary.freePlanName.text}
                description={input.primary.freePlanDescription.text}
                features={input.primary.freePlanFeatures}
              />
            </div>
            <div className="pricing-table-col">
              <div className="pricing-table-tabs">
                {input.items.map(item => (
                  <Link
                    key={item.userCount}
                    to="#"
                    onClick={event => this.setPlanPrice(event, item.userCount)}
                    className={activeTab === item.userCount ? 'pricing-table-tab active' : 'pricing-table-tab'}
                  >
                    {item.userCount} Users
                  </Link>
                ))}
              </div>
              <PricingTablePlan
                className="medium-plan"
                name={input.primary.mediumPlanName.text}
                price={mediumPlanPrice}
                description={input.primary.mediumPlanDescription.text}
                features={input.primary.mediumPlanFeatures}
              />
              <PricingTablePlan
                className="enterprise-plan mobile-up"
                name={input.primary.enterprisePlanName.text}
                price={enterPrisePlanPrice}
                description={input.primary.enterprisePlanDescription.text}
                features={input.primary.enterprisePlanFeatures}
              />
            </div>
            <div className="pricing-table-col enterprise-plan-wrapper mobile-down">
              <div className="pricing-table-tabs">
                {input.items.map(item => (
                  <Link
                    key={item.userCount}
                    to="#"
                    onClick={event => this.setPlanPrice(event, item.userCount)}
                    className={activeTab === item.userCount ? 'pricing-table-tab active' : 'pricing-table-tab'}
                  >
                    {item.userCount} Users
                  </Link>
                ))}
              </div>
              <PricingTablePlan
                className="enterprise-plan"
                name={input.primary.enterprisePlanName.text}
                price={enterPrisePlanPrice}
                description={input.primary.enterprisePlanDescription.text}
                features={input.primary.enterprisePlanFeatures}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

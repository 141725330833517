import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Header, Footer, SEO } from 'components';
import 'typeface-hind-vadodara';
import '../sass/global/global.scss';

const PureLayout = ({ children, data, pageTitle, isHome, noHeader, noFooter }) => (
  <>
    <SEO title={pageTitle} />
    {!noHeader && <Header isHome={isHome} />}
    {children}
    {!noFooter && <Footer data={data.prismicHome.data} />}
  </>
);

class Layout extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            prismicHome {
              data {
                title {
                  text
                }
              }
            }
          }
        `}
        render={data => <PureLayout {...this.props} data={data} />}
      />
    );
  }
}

export default Layout;

import React, { Component } from 'react';
import {
  Hero,
  IconGrid,
  Cta,
  Slider,
  PricingTable,
  Accordion,
  Testimonials,
  BodyText,
  CodeBlock,
  Image,
  Quote,
} from 'slices';

export default class SliceZone extends Component {
  render() {
    const { allSlices } = this.props;
    const sliceComponent = allSlices.map(slice => {
      switch (slice.sliceType) {
        // These are the API IDs of the slices
        case 'hero':
          return <Hero key={slice.id} input={slice} />;
        case 'icon_grid':
          return <IconGrid key={slice.id} input={slice} />;
        case 'cta':
          return <Cta key={slice.id} input={slice} />;
        case 'slider':
          return <Slider key={slice.id} input={slice} />;
        case 'pricing_table':
          return <PricingTable key={slice.id} input={slice} />;
        case 'accordion':
          return <Accordion key={slice.id} input={slice} />;
        case 'testimonials':
          return <Testimonials key={slice.id} input={slice} />;
        case 'text':
          return <BodyText key={slice.id} input={slice} />;
        case 'code_block':
          return <CodeBlock key={slice.id} input={slice} />;
        case 'image':
          return <Image key={slice.id} input={slice} />;
        case 'quote':
          return <Quote key={slice.id} input={slice} />;
        default:
          return console.log('code me', slice);
      }
    });
    return sliceComponent;
  }
}

import React, { Component } from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Slope } from 'components';
import 'tiny-slider/src/tiny-slider.scss';
import './Slider.scss';

const isOdd = number => number % 2;

const SlideButtons = ({ buttons }) => {
  if (!buttons) return null;
  const hasTwo = buttons.length === 2;
  return (
    <div className={`button-group ${hasTwo ? 'with-two' : ''}`}>
      {buttons.map((button, index) => {
        const isInternalLink = button.buttonLink.url.indexOf('#') !== -1;
        const LinkComponent = isInternalLink ? AnchorLink : Link;
        return (
          <LinkComponent
            key={button.buttonText.text}
            className={`button ${index === 0 && hasTwo ? 'outline-white' : 'white'}`}
            href={isInternalLink ? `#${button.buttonLink.url.split('#')[1]}` : null}
            to={isInternalLink ? null : button.buttonLink.url}
          >
            {button.buttonText.text}
          </LinkComponent>
        );
      })}
    </div>
  );
};

const SliderSlides = ({ slides }) => {
  if (!slides) return null;
  return (
    <div className="slider">
      {slides.map((slide, index) => {
        if (
          !slide.image ||
          !slide.image.localFile ||
          !slide.image.localFile.childImageSharp ||
          !slide.image.localFile.childImageSharp.fluid
        )
          return null;
        return (
          <div key={slide.title.text}>
            <div className={isOdd(index) ? 'slider-item odd' : 'slider-item'}>
              <div className="slider-item-image">
                <Img fluid={slide.image.localFile.childImageSharp.fluid} />
              </div>
              <div className="slider-item-content">
                <h3>{slide.title.text}</h3>
                <p>{slide.content.text}</p>
                <SlideButtons
                  buttons={[
                    {
                      buttonText: slide.linkTitleLeft,
                      buttonLink: slide.linkUrlLeft,
                    },
                    {
                      buttonText: slide.linkTitleRight,
                      buttonLink: slide.linkUrlRight,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default class Slider extends Component {
  componentDidMount() {
    try {
      // eslint-disable-next-line
      const { tns } = require('tiny-slider/src/tiny-slider.module.js');
      tns({
        container: '.slider',
        items: 1,
        nav: true,
        controls: false,
        mouseDrag: true,
        // loop: false,
        // mode: 'gallery',
      });
    } catch (error) {
      console.error('Error firing slider', error);
    }
  }

  render() {
    const { input } = this.props;
    return (
      <section className="slider-container" id="features">
        <div className="wrapper">
          <SliderSlides slides={input.items} />
        </div>
        <Slope position="top" background="#F9F9F9" />
      </section>
    );
  }
}
